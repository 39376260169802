<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<a-button @click="onSearch">						
					<template #icon><Icon icon="ReloadOutlined"/></template>
					刷新</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_banner_add']" type="primary" @click="onAdd">新增轮播图</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="标题" name="title" class="ui-form__item">
						<a-input v-model:value="formModal.title" placeholder="请输入标题"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formModal.isDisabled" style="width: 190px;" placeholder="请选择轮播图状态">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'img'">
							<a-image style="width: 60px;" :src="record.img"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_banner_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_banner_open']" v-if="record.isDisabled === 1" type="link" size="small" @click="onDisabled(record)">启用</a-button>
							<a-button v-permission="['pc_system_banner_disabled']" v-else type="link" size="small" @click="onDisabled(record)">禁用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_banner_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table class="table"
					rowKey="id"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1000 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'img'">
							<a-image style="width: 60px;" :src="record.img"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_banner_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_banner_open']" v-if="record.isDisabled === 1" type="link" size="small" @click="onDisabled(record)">启用</a-button>
							<a-button v-permission="['pc_system_banner_disabled']" v-else type="link" size="small" @click="onDisabled(record)">禁用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_banner_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModal" @refresh="onReset"></saveModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getBannerList, deleteBanner, pullOffShelvesBanner, pullOnShelvesBanner } from '@/service/modules/platform.js';
	export default {
		components: { Icon, saveModal, cTable },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					isDisabled: -1
				},
				getDataFun: getBannerList,
				list: [],
				clearTime: [], // 存储时间 用来清空重置
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 100
				}, {
					title: '标题',
					align: 'center',
					dataIndex: "title"
				}, {
					title: '轮播图',
					align: 'center',
					key: 'img'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 180,
					align: 'center'
				}, {
					title: '操作',
					key: 'action',
					width: 160,
					fixed: 'right'
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getBannerList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.searchData.isDisabled = this.searchData.isDisabled !== -1 ? this.searchData.isDisabled : undefined;
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onAdd() {
				this.$refs.saveModal.open(false);
			},
			onEdit(item) {
				this.$refs.saveModal.open(item);
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled === 1 ? '启用' : '禁用') + '吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret;
							if (item.isDisabled === 1) {
								ret = await pullOnShelvesBanner({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesBanner({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled === 1 ? '启用' : '禁用') +'成功');
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteBanner({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								// this.getData()
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
</style>