<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑轮播图' : '新增轮播图'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<a-form-item name="title" label="标题" :rules="[{ required: true, message: '请输入标题!' }]">
						<a-input v-model:value="modelRef.title" placeholder="请输入轮播图标题"></a-input>
					</a-form-item>
					
					<a-form-item name="img" required label="轮播图" :rules="[{ required: true, message: '请输入轮播图标题!' }]">
						<imageUpload v-model:value="modelRef.img" :multiple="false" :maxSize="10"></imageUpload>
						<p class="ui-upload__warn">注：请上传图片文件，文件大小不超过10M</p>
					</a-form-item>
					
					<a-form-item name="sort" label="排序">
						<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						<p class="ui-upload__warn">注：不输入默认为0, 数值越大越靠前</p>
					</a-form-item>
					
					<a-form-item name="isDisabled" label="状态" :rules="[{ required: true, message: '请选择状态!' }]">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import imageUpload from '@/components/upload/imageUpload.vue';
	import { saveBanner, getBannerDetail, updateBanner } from '@/service/modules/platform.js';
	export default {
		components: { Icon, modalHeader, imageUpload },
		data() {
			return {
				visible: false,
				isEdit: false,
				modelRef: {
					img: [],
					isDisabled: 1
				},
				fileList: []
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.img = this.modelRef.img.toLocaleString();
					if(postData.img.lenght !== 0){
						this.loading = true;
						try {
							let ret;
							if (this.isEdit) {
								ret = await updateBanner(postData);
							} else {
								ret = await saveBanner(postData);
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
								this.$emit('refresh');
								this.close();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = JSON.parse(JSON.stringify(e));
					// this.getDetail(e.id);
					this.isEdit = true;
				} else {
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.resetFields();
				this.modelRef = {
					img: [],
				}
				this.visible = false;
			},
			async getDetail(id) {
				try {
					let ret = await getBannerDetail({
						id: id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				} 
			}
		}
	}
</script>

<style scoped>
	.ui-upload__warn {
		/* margin-top: 6px; */
		margin-bottom: 0;
		font-size: 12px;
		color: #999;
	}
</style>